<template>
  <div>
    <b-card-body class="p-2">

      <div class="mb-2">
        <b-button
          size="sm"
          :variant="sRateId===null?'info':'outline-info'"
          @click="sRateId=null"
          class="mr-2">ทั้งหมด</b-button>
        <b-button
          v-for="rate in rates"
          size="sm"
          :variant="sRateId===rate._id?'info':'outline-info'"
          @click="sRateId=rate._id"
          :key="rate._id"
          class="mr-2">{{rate.rateTitle}}</b-button>
      </div>

      <table class="table table-bordered table-info table-hover mb-0">
        <thead>
          <tr>
            <th rowspan="2">ประเภท</th>
            <th rowspan="2" width="10%" style="border-right: 2px solid #86cfda;">ยอดทั้งหมด</th>
            <th colspan="4" style="border-right: 2px solid #86cfda;">สมาชิก</th>
            <th colspan="4" style="border-right: 2px solid #86cfda;">{{agentName}}</th>
            <th colspan="4">บริษัท</th>
          </tr>
          <tr>
            <th width="6%" class="p-1">ส่งออก</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1" style="border-right: 2px solid #86cfda;">รวม</th>
            <th width="6%" class="p-1">ถือหุ้น</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1" style="border-right: 2px solid #86cfda;">รวม</th>
            <th width="6%" class="p-1">ถือหุ้น</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1">รวม</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="14" class="text-center">กรุณารอซักครู่</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading">
          <tr v-for="(item, index) in items" :key="item.code">
            <td class="text-center">{{item.text}}</td>
            <td v-if="index===0" :rowspan="items.length" class="text-right px-1 text-success" style="border-right: 2px solid #86cfda;">{{summary.amount | amountFormat(2, '0.00')}}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberAmount>0
              }, {
                'text-danger': summary.memberAmount<0
              }]"
            >{{summary.memberAmount | amountFormat(2, '0.00')}}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberCommission>0
              }, {
                'text-danger': summary.memberCommission<0
              }]"
            >{{summary.memberCommission | amountFormat(2, '0.00')}}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': item.memberWon>0
              }, {
                'text-danger': item.memberWon<0
              }]"
            >{{item.memberWon | amountFormat(2, '0.00')}}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberTotal>0
              }, {
                'text-danger': summary.memberTotal<0
              }]"
              style="border-right: 2px solid #86cfda;"
            >{{summary.memberTotal | amountFormat(2, '0.00')}}</td>

            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.agentWon>0
              }, {
                'text-danger': item.agentWon<0
              }]">{{ item.agentWon | amountFormat(2, '0.00') }}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              style="border-right: 2px solid #86cfda;"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>

            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': item.companyWon>0
              }, {
                'text-danger': item.companyWon<0
              }]">{{ item.companyWon | amountFormat(2, '0.00') }}</td>
            <td
              v-if="index===0"
              :rowspan="items.length"
              class="text-right px-1"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tbody>
        <tfoot v-if="!isLoading && items.length">
          <tr>
            <th class="text-center">รวม</th>
            <td class="text-right px-1 text-success" style="border-right: 2px solid #86cfda;">{{ summary.amount | amountFormat(2, '0.00') }}</td>

            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberAmount>0
              }, {
                'text-danger': summary.memberAmount<0
              }]"
            >{{ summary.memberAmount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberCommission>0
              }, {
                'text-danger': summary.memberCommission<0
              }]"
            >{{ summary.memberCommission | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberWon>0
              }, {
                'text-danger': summary.memberWon<0
              }]"
            >{{ summary.memberWon | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              style="border-right: 2px solid #86cfda;"
              :class="[{
                'text-success': summary.memberTotal>0
              }, {
                'text-danger': summary.memberTotal<0
              }]"
            >{{ summary.memberTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentWon>0
              }, {
                'text-danger': summary.agentWon<0
              }]">{{ summary.agentWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1" style="border-right: 2px solid #86cfda;"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyWon>0
              }, {
                'text-danger': summary.companyWon<0
              }]">{{ summary.companyWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tfoot>
      </table>
    </b-card-body>
  </div>
</template>
<script>
import SetsReportService from "@/services/SetsReportService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import cAlert from '@/helpers/alert'
import { marketTypes } from "@/configs/market.config"

export default {
  name: 'SetsWinLoseByType',
  props: ['agentAccountId', 'memberAccountId', 'rateId'],
  data() {
    return {
      data: null,
      sRateId: null,
      isLoading: false
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    changed() {
      return `${this.roundId}-${this.agentAccountId}-${this.memberAccountId}`
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    market() {
      return this.data?.market ?? null
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.market?.openBets?.[o.code]
      })
    },
    items() {
      const defaultData = this.marketOpenBets.reduce((def, o)=>{
        def[o.code] = {
          memberWon: 0,
          agentWon: 0,
          companyWon: 0
        }
        return def
      }, {})

      const winlose = (this.data?.winlose ?? [])
      .filter((s)=>{ return s._id.rateId === this.sRateId || !this.sRateId })
      .reduce((total, r_winlose)=>{
        total[r_winlose._id.type].memberWon += r_winlose.memberWon
        total[r_winlose._id.type].agentWon += r_winlose.agentWon
        total[r_winlose._id.type].companyWon += r_winlose.companyWon
        return total
      }, defaultData)

      return this.marketOpenBets.map((o)=>{
        return {
          ...o,
          ...winlose[o.code]
        }
      })
    },
    totalWinlose() {
      return this.items.reduce((total, item)=>{
        total.memberWon += item.memberWon
        total.memberTotal += item.memberWon
        total.agentWon += item.agentWon
        total.agentTotal += item.agentWon
        total.companyWon += item.companyWon
        total.companyTotal += item.companyWon
        return total
      }, {
        memberWon: 0,
        memberTotal: 0,
        agentWon: 0,
        agentTotal: 0,
        companyWon: 0,
        companyTotal: 0
      })
    },
    summary() {
      return (this.data?.summary ?? [])
      .filter((s)=>{ return s._id.rateId === this.sRateId || !this.sRateId })
      .reduce((total, r_amount)=>{
        total.amount += r_amount.amount
        total.memberAmount += r_amount.memberAmount
        total.memberCommission += r_amount.memberCommission
        total.memberTotal += r_amount.memberTotal
        total.agentAmount += r_amount.agentAmount
        total.agentCommission += r_amount.agentCommission
        total.agentTotal += r_amount.agentTotal
        total.companyAmount += r_amount.companyAmount
        total.companyCommission += r_amount.companyCommission
        total.companyTotal += r_amount.companyTotal
        return total
      }, {
        ...this.totalWinlose,
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        agentAmount: 0,
        agentCommission: 0,
        companyAmount: 0,
        companyCommission: 0
      })
    },
    rates() {
      return this.data?.rates ?? []
    }
  },
  watch: {
    changed() {
      this.getReport()
    }
  },
  methods: {
    getReport() {
      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType!=='lottoset') {
        return this.$router.push({name: 'RoundMemberWinLose'})
      }

      if(!this.roundId)
        return

      this.isLoading = true
      SetsReportService.getRoundReportByType(this.roundId, {
        accountId: this.agentAccountId,
        memberAccountId: this.memberAccountId,
        // rateId: this.sRateId
      })
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', response.data)
          this.$emit('Breadcrumbs', response.data.agent.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.sRateId = this.rateId
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: normal;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>

import api from "@/services/apiService"

class SetsReportService {

  /**
   * ดึงรายงานของรวม
   */
  getRoundOverall(roundId, data={}) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_READ_TICKET + 'sets-report/overall/' + roundId, data, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการแทง
   */
  getItems(roundId, data, offset=0) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}sets-report/items/${roundId}`+(offset?`/${offset}`:''), data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงยอดแทงของสมาชิก
   */
  getRoundReportByMember(roundId, data = {status:'wait', accountId:null, rateId: null}) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}sets-report/by-member/${roundId}`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการตัวเลขแทง
   */
  getRoundMemberBetNumbers(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}sets-report/bet-numbers/${roundId}`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงยอดแทงตามประเภทการแทง
   */
  getRoundReportByType(roundId, data) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_READ_TICKET}sets-report/by-type/${roundId}`, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new SetsReportService()
